import './App.css'
import React, { useState } from 'react'
import Sheet from 'react-modal-sheet';
import ReactPlayer from 'react-player'
import useWindowDimensions from './WindowDimensions'
import { Space, Tab } from './Spaces'
import { PrivacyPolicyDetails } from './PrivacyPolicy'
import { TwitterLogo, DiscordLogo, UltraTempusLogo } from './Logos'
import { XMarkIcon } from './Icons'
import BackgroundVideo from './videos/UltraTempusVideoBackground.mp4'

const styles = {
	glowButtonOuter: {
		position: 'relative',
		top: '-32px',
		left: '32px',
		marginBottom: '-64px',
		marginLeft: '-64px'
	},
	glowButtonInner: {
		padding: '32px'
	}
}

function App() {
	const { height, width } = useWindowDimensions();
	const [showPrivacy, setShowPrivacy] = useState(false);
	const [showTrailer, setShowTrailer] = useState(false);
	const mobileWidth = 768;
	const mobileHeight = 512;

	let footer;
	if (width < mobileWidth) {
		footer = <div style={{ fontSize: '0.625em' }}>
			<footer style={{ bottom: '40pt' }}>
				<Copyright />
			</footer>
			<footer style={{ bottom: '8pt' }}>
				<div style={styles.glowButtonOuter}>
					<button style={styles.glowButtonInner} onClick={() => setShowPrivacy(true)}>
						Privacy Policy
					</button>
				</div>
			</footer>
		</div>
	}
	if (height < mobileHeight) {
		footer = <footer style={{ fontSize: '0.5em' }}>
			<Copyright />
			<Tab /><Tab />
			<div style={styles.glowButtonOuter}>
				<button style={styles.glowButtonInner} onClick={() => setShowPrivacy(true)}>
					Privacy Policy
				</button>
			</div>
		</footer>
	}
	if (width > mobileWidth && height > mobileHeight) {
		footer = <footer>
			<Copyright />
			<Tab /><Tab />
			<div style={styles.glowButtonOuter}>
				<button style={styles.glowButtonInner} onClick={() => setShowPrivacy(true)}>Privacy Policy</button>
			</div>
		</footer>
	}

	let topMargin;
	let mainButtons;
	if (height < mobileHeight) {
		topMargin = '12pt';
		mainButtons = <div style={{ fontSize: '0.75em' }}>
			<div className='horizontalCenter'>
				<div style={styles.glowButtonOuter}>
					<button style={styles.glowButtonInner} onClick={() => setShowTrailer(true)}>Watch Trailer</button>
				</div>
				<Tab /><Tab />
				<BetaSignup />
			</div>
			<Connect fontSize='0.75em' />
		</div>
	} else {
		topMargin = '56pt';
		mainButtons = <>
			<div className='horizontalCenter'>
				<div style={styles.glowButtonOuter}>
					<button style={styles.glowButtonInner} onClick={() => setShowTrailer(true)}>Watch Trailer</button>
				</div>
			</div>
			<div className='horizontalCenter'>
				<BetaSignup />
			</div>
			<Connect fontSize='1em' />
		</>
	}

	let trailerVideo;
	let videoBackgroundColor;
	if (showTrailer) {
		const padding = (min(width, height) * 0.01) * 10

		let xRatio = 1920.0 / width;
		let yRatio = 1080.0 / height;
		let videoWidth = 1920.0;
		let videoHeight = 1080.0;

		let videoXOffset = 0;
		let videoYOffset = 0;

		if (xRatio > yRatio) {
			if (videoWidth !== width) {
				let aspect = videoHeight / videoWidth;
				let newHeight = aspect * (width - padding);
				videoWidth = width - padding;
				videoHeight = newHeight;
			}
		} else {
			if (videoHeight !== height) {
				let aspect = videoWidth / videoHeight;
				let newWidth = aspect * (height - padding);
				videoWidth = newWidth;
				videoHeight = height - padding;
			}
		}

		videoYOffset = (height - videoHeight) * 0.5
		videoXOffset = (width - videoWidth) * 0.5

		trailerVideo = <>
			<button
				style={{
					backgroundColor: '#00000055',
					left: '0px',
					top: '0px',
					position: 'fixed',
					zIndex: '9999',
					minWidth: '100%',
					minHeight: '100%',
				}}
				onClick={() => setShowTrailer(false)}
			/>
			<ReactPlayer
				url={'https://vimeo.com/569865900'}
				width={videoWidth}
				height={videoHeight}
				style={{
					position: 'fixed',
					left: videoXOffset,
					top: videoYOffset,
					zIndex: '10000'
				}}
			/>
		</>
		videoBackgroundColor = '#000000DD'
	} else {
		trailerVideo = <></>
		videoBackgroundColor = '#00000077'
	}

	return (
		<>
			{Background(width, height, videoBackgroundColor)}
			{trailerVideo}
			<div className='horizontalCenter' style={{ marginTop: topMargin }} >
				<div >
					<div className='horizontalCenter'>
						<UltraTempusLogo />
					</div>
					<div className='shFont'>
						{mainButtons}
					</div>
				</div>
				<div className='shFont'>
					{footer}
				</div>
			</div >
			<Sheet isOpen={showPrivacy} onClose={() => setShowPrivacy(false)}>
				<Sheet.Container style={{
					display: 'flex',
					alignSelf: 'center',
					justifySelf: 'center',
					borderTopLeftRadius: '24pt',
					borderTopRightRadius: '24pt'
				}}>
					<Sheet.Content>
						<div className='horizontalCenter'>
							<PrivacyPolicyDetails />
							<div style={{ position: 'relative', maxWidth: '80vw' }}>
								<button style={{
									position: 'absolute',
									top: '10pt',
									right: '-10vmin'
								}} onClick={() => setShowPrivacy(false)}>
									<XMarkIcon size='44pt' style={{ color: '#111111FF' }} />
								</button>
							</div>
						</div>
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet>
		</>
	);
}

function Background(width, height, background) {
	let xRatio = 1280.0 / width;
	let yRatio = 720.0 / height;
	let videoWidth = 1280.0;
	let videoHeight = 720.0;

	let videoXOffset = 0;
	let videoYOffset = 0;

	if (xRatio < yRatio) {
		if (videoWidth !== width) {
			let aspect = videoHeight / videoWidth;
			let newHeight = aspect * width;
			videoWidth = width;
			videoHeight = newHeight;
			videoYOffset = -(videoHeight - height) * 0.5
		}
	} else {
		if (videoHeight !== height) {
			let aspect = videoWidth / videoHeight;
			let newWidth = aspect * height;
			videoWidth = newWidth;
			videoHeight = height;
			videoXOffset = -(videoWidth - width) * 0.5
		}
	}

	return (
		<>
			<ReactPlayer
				url={BackgroundVideo}
				muted='true'
				loop='true'
				playing='true'
				width={videoWidth}
				height={videoHeight}
				playsinline='true'
				style={{
					left: videoXOffset,
					top: videoYOffset,
					position: 'fixed',
					zIndex: '-10000'
				}}
			/>
			<div style={{
				backgroundColor: background,
				left: '0px',
				top: '0px',
				position: 'fixed',
				zIndex: '-9999',
				minWidth: '100%',
				minHeight: '100%'
			}} />
		</>
	)
}

function Copyright() {
	return <>
		©2021<Space />
		<a href='http://ryanjboyer.com' className='textLink'>Ryan Boyer</a>
		<Space />/<Space />
		<a href='https://trudatsound.com' className='textLink'>Charlie Knox</a>
	</>
}

function Connect(props) {
	return <>
		<div className='horizontalCenter'>
			<div style={{ fontSize: props.fontSize }}>
				Connect
				<a href='https://twitter.com/UltraTempus'><TwitterLogo /></a>
				<a href='https://discord.gg/9htH2Kgn9N'><DiscordLogo /></a>
			</div>
		</div>
		<div className='horizontalCenter'>
			<div style={{ fontSize: props.fontSize }}>
				<a href='https://soundcloud.com/trudatsound/sets/ultra-tempuis-ost' className='textLink'>Soundtrack</a>
			</div>
		</div>
	</>
}

function BetaSignup() {
	return <a href='https://forms.gle/aShmXj3jiwf6MRxR6' className='textLink'>Beta Test Signup</a>
}

function min(lhs, rhs) {
	if (lhs < rhs) {
		return lhs
	}
	return rhs
}

export default App;
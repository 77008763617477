import React from 'react'

export function PrivacyPolicyDetails() {
    return <div className='horizontalCenter'>
        <div style={{
            maxWidth: '80vmin',
            fontFamily: 'Roboto',
            fontSize: '0.75em',
            textShadow: '0px 0px 0px #00000000',
            color: '#111111FF',
            paddingTop: '8pt',
            paddingBottom: '12pt',
            overflow: 'scroll'
        }}>
            <h2>
                Developed With Love
                <br />Privacy Policy
            </h2>
            <h3>Last updated: November 6, 2021</h3>
            <p>
                Developed With Love does not use cookies.
                <br />Developed With Love does not use trackers.

                <br /><br />The Developed With Love server tracks website access to enable protection against distributed denial of service (DDOS) attacks.  Unfortunately, this means that IP addresses, operating systems, and browsers are tracked and stored on a rotating basis.

                <br /><br />Developed With Love does not collect any additional personal data
                <br />- Developed With Love does not use your personal data.
                <br />- Developed With Love does not share or disclose your personal data.
                <br />- Developed With Love does not sell your personal data.

                <br /><br />The Ultra Tempus game uses an opt-in service that creates a unique identifier and a user-submitted name to upload scores to an online leaderboard.  To opt out of this, do not log into the online leaderboards in the game.

                <br /><br />ultratempus.com links to other websites that may collect personal data.
            </p>
            <p>
                If you have any questions about our Privacy Policy or information practices,
                <br />please feel free to contact us at our <a href='mailto:ryanjboyer+privacy@gmail.com'>designated request address</a>.
            </p>
        </div>
    </div>
}
import React from 'react'
import UltraTempus from './UltraTempusLogo.svg'
import Twitter from './TwitterLogo.svg'
import Discord from './DiscordLogo.svg'

export function UltraTempusLogo() {
    return <img
        src={UltraTempus}
        alt='Ultra Tempus Logo'
        style={{
            maxWidth: '90vmin'
        }}
    />
}

export function TwitterLogo() {
    return <img
        src={Twitter}
        alt='Twitter Logo'
        style={{
            height: '2em',
            position: 'relative',
            top: '0.55em',
            marginLeft: '-0.25em',
            marginRight: '-0.25em'
        }}
    />
}

export function DiscordLogo() {
    return <img
        src={Discord}
        alt='Discord Logo'
        style={{
            height: '2em',
            position: 'relative',
            top: '0.55em',
            marginLeft: '-0.25em',
            marginRight: '-0.5em'
        }}
    />
}